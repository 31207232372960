<template>
  <div>
    <ModalEditMailText
      v-if="showEditMailText"
      :showModal="showEditMailText"
      :onClickCancel="hideModal"
      :mailText="selectedMailText"
      @mailTextUpdated="updateMailText"
    />

    <p class="title">Mail texts</p>

    <div class="select">
      <select v-model="selectedMailType">
        <option :value="null" :selected="selectedMailType === null"
          >Select dropdown</option
        >
        <option
          v-for="mailType in mailTypes"
          :key="mailType.Id"
          :value="mailType"
          >{{ mailType.Type }}</option
        >
      </select>
    </div>

    <div v-if="mailTexts.length > 0 && selectedMailType" class="columns">
      <div
        v-for="(text, index) in mailTexts.filter(
          (mt) => mt.MailType === selectedMailType.Type
        )"
        :key="index"
        class="column"
      >
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                <div class="level">
                  <div class="level-left">
                    <div class="subtitle">{{ text.Language }}</div>
                  </div>
                  <div class="level-right">
                    <a
                      @click="setShowEditText(text)"
                      class="has-icon has-margin-left"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'edit']" />
                      </span>
                      <span>Edit</span>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ text.Subject }}</td>
            </tr>
            <tr>
              <td><div v-html="text.Body"></div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import channelProvider from '@/providers/channel'

export default {
  components: {
    ModalEditMailText: () => import('@/components/Settings/ModalEditMailText'),
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      mailTexts: [],
      mailTypes: [],
      selectedMailType: null,
      selectedMailText: null,
      showEditMailText: false,
    }
  },

  computed: {},

  created() {
    this.mailTypes.push({ Id: 1, Type: 'ReservationCreatedLocation' })
    this.mailTypes.push({ Id: 35, Type: 'ReservationRequestedLocation' })
    this.mailTypes.push({ Id: 2, Type: 'ReservationUpdatedLocation' })
    this.mailTypes.push({ Id: 3, Type: 'ReservationCancelledLocation' })
    this.mailTypes.push({ Id: 4, Type: 'ReservarionCreatedBooker' })
    this.mailTypes.push({ Id: 34, Type: 'ReservationRequestedBooker' })
    this.mailTypes.push({ Id: 16, Type: 'NewProfileByUser' })
    this.mailTypes.push({ Id: 17, Type: 'PasswordResetByUser' })

    this.getChannelMailTexts()
  },

  methods: {
    getChannelMailTexts() {
      if (!this.isLoading) {
        this.isLoading = true

        channelProvider.methods
          .getChannelMailTexts(this.channelId)
          .then((response) => {
            if (response.status === 200) {
              this.mailTexts = response.data
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    updateMailText(mailText) {
      let index = this.mailTexts.findIndex((mt) => mt.Id === mailText.Id)
      if (index > -1) {
        Vue.set(this.mailTexts, index, mailText)
      }
    },

    setShowEditText(text) {
      this.showEditMailText = true
      this.selectedMailText = text
    },

    hideModal() {
      this.showEditMailText = null
      this.showEditMailText = false
    },
  },
}
</script>

<style></style>
